@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Acme&family=Delius+Unicase&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}








.progress-ring__circle {
  stroke-dasharray: 400, 400;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.circle {
  transform-origin: center center;
}
.circle:nth-child(2) {
  transform: rotate(-90deg);
}






/*
#root {
    margin: 100px auto;
    max-width: 500px;
    padding-bottom: 100px;
  }
  
  .card {
    font-size: 164px;
    width: 300px;
    height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
      0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
      0 0 16px hsl(0deg 0% 0% / 0.075);
    transform-origin: 10% 60%;
  }
  
  .card-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 20px;
    margin-bottom: -120px;
  }
  
  .splash {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    clip-path: path(
      "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
    );
  }*/